import React from 'react';
import clsx from 'clsx';
import styles from './section.module.scss';

export function Section({ id, children, variant = 'primary', ...other }) {
  return (
    <div id={id} className={clsx(styles.wrapper)} {...other}>
      <div
        className={clsx(
          styles.section,
          variant === 'primary' && styles.primary,
          variant === 'secondary' && styles.secondary,
          variant === 'dark-secondary' && styles.darkSecondary,
          variant === 'dark' && styles.dark
        )}
      >
        {children}
      </div>
    </div>
  );
}
