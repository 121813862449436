import React, { useState } from 'react';

import SEO from '../components/seo';
import { LayoutContent } from '../components/layout/layout-content';
import { Section } from '../components/section/section';
import { sectionData } from '../components/sections/sections';
import { SectionIndicator } from '../components/section-indicator/section-indicator';

import styles from '../styles/pages/index.module.scss';

const Index = () => {
  const { sections } = sectionData();
  const [activeMenu, setActiveMenu] = useState(`#${sections[0].id}`);

  const handleScroll = (e) => {
    for (let i = 0; i < sections.length + 1; i++) {
      const el = document.querySelector(`.${styles.container}`).childNodes[0].childNodes[i];
      const bounding = el.getBoundingClientRect();

      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      ) {
        const inViewId = el.id;

        if (inViewId) {
          setActiveMenu(`#${inViewId}`);
        }
      }
    }
  };

  return (
    <LayoutContent
      className={styles.container}
      activeMenu={activeMenu}
      setActiveMenu={setActiveMenu}
      onScroll={handleScroll}
      hiddenOverflow={true}
      fixedFooter={true}
    >
      <SEO title="Andreas Straub | Home" />
      <SectionIndicator sections={sections} activeSection={activeMenu.replace('#', '')} />
      {sections.map(({ id, variant, section: Component }) => (
        <Section key={id} id={id} variant={variant}>
          <Component />
        </Section>
      ))}
    </LayoutContent>
  );
};

export default Index;
